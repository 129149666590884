import cn from "classnames";
import "./App.css";
import telegram from "./public/telegram.svg";
import conversations from "./public/conversations.svg";
import ad from "./public/ad.svg";
import arrow from "./public/arrow.png";
import create from "./public/create.png";
import audio from "./public/audio.png";
import web from "./public/web.png";
import money from "./public/money.png";
import monets from "./public/monets.png";
import rocket from "./public/rocket.png";

function App() {
  return (
    <div className="App">
      <div className={cn("backgroundLight", "lightOne")}></div>
      <div className={cn("backgroundLight", "lightTwo")}></div>
      <div className={cn("boldText", "topText", "bigText")}>
        Создание веб приложений в <br />
        Телеграмм
        <br /> и рекламное сопровождение
      </div>
      <img className="topImg" src={arrow} />
      <div className={cn("boldText", "middleText", "howItWorks")}>
        Как это работает?
      </div>
      <div className="worksBlock">
        <div className="workBox">
          <img className="workBoxImg" src={web} />
          <span className={cn("smallText", "grayText")}>Создадим страницу</span>
        </div>
        <div className="workBox">
          <img className="workBoxImg" src={audio} />
          <span className={cn("smallText", "grayText")}>
            Настроим продвижение
          </span>
        </div>
        <div className="workBox">
          <img className="workBoxImg" src={money} />
          <span className={cn("smallText", "grayText")}>Получаете продажи</span>
        </div>
      </div>
      <a
        className="createBlock"
        href="https://t.me/web_miniApp_bot?start=start"
      >
        <div className="createBlockTextContainer">
          <img className="createBlockImg" src={create}></img>
          <div className="createTextBlock">
            <div className={cn("smallMiddleText", "grayText")}>
              web приложение
            </div>
            <div className="smallMiddleText">в Телеграм</div>
          </div>
        </div>
        <button className="buttonSmall">Создать</button>
      </a>
      <a href="https://t.me/trafikvse" className="buttonTransparentLink">
        <button className="buttonTransparent">
          <span>Подписаться на канал</span>
          <img src={telegram} className="App-logo" />
        </button>
      </a>
      <div className={cn("smallMiddleText", "grayText", "devText")}>
        Разработает для Вас страницу для продвижение вашего бизнеса в Телеграм
      </div>
      <div className="bottomText">
        Будьте первыми и получайте <br />
        максимум <br />
        от реклама в Телеграм !
      </div>
      <img className="middleImg" src={monets} />
      <div className={cn("smallMiddleText", "grayText", "bottomTextNext")}>
        Максимальная конверсия, <br />
        забудьте о чат-ботах <br />и получайте клиентов уже сейчас
      </div>
      <a href="https://t.me/web_miniApp_bot?start=start">
        <button className="buttonBig">
          <span>Быстрый старт</span>
        </button>
      </a>
      <div className={cn("smallMiddleText", "grayText", "onlineText")}>
        Онлайн продвижение вашего бизнеса
        <br /> от рекламы до bi-аналитики
      </div>
      <ul className="textArr">
        <li>Разработаем стратегию продвижения</li>
        <li>Составим медиаплан и бюджет</li>
        <li>Закупим и настроим рекламу </li>
        <li>Настроим воронку продаж</li>
        <li>Создадим дашборд для аналитики</li>
      </ul>
      <img className="rocketImg" src={rocket} />
      <div className="hugeText">Запустим Ваш бизнес </div>
      <div className="middleText">в Технологический прорыв</div>
      <a
        className="launchBlock"
        href="https://t.me/web_miniApp_bot?start=start"
      >
        <div className="createBlockTextContainer">
          <img className="createBlockImg" src={ad} />
          <div className="adText">Рекламная компания</div>
        </div>
        <button className="buttonSmall">
          <span className="">Запуск</span>
        </button>
      </a>
    </div>
  );
}

export default App;
